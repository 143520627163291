







import { defineComponent  } from "@vue/composition-api";
import DefaultLayout from "@/layouts/default.vue"; 
import ContainerApproval from "@/components/containers/ContainerApproval.vue";
export default defineComponent({
   components: {
   DefaultLayout,
   ContainerApproval,
   },
   created(){
     this.$store.commit("SET_BREADCRUMB", {
        viewTxt: "Gửi đến tôi",
        ableToBack: false,
        rootRouter: "/",
        hasStatus: false,
        statusTxt: "",
     });
   },
   methods:{
      
   }
});
